import { queryOptions, useQuery } from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns/minutesToMilliseconds'
import orderBy from 'lodash/orderBy'
import {
  useUserHasPlacedFirstOrder,
  useUserId,
} from '@/features/account/services/User/hooks'
import {
  type NormalizedOrderHistoryResponse,
  type Order,
} from '@/features/shop/services/Orders/types'
import { useStoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import {
  fetchActiveOrders,
  fetchLastDeliveredOrder,
  fetchOrder,
  fetchOrderTracking,
  fetchOrdersHistory,
} from '@/features/shop/services/Orders/fetchers'
import { secondsToMilliseconds } from 'date-fns/secondsToMilliseconds'
import { useOrderEngagementQuery } from '@/features/shop/services/OrderEngagement/graphql/orderEngagement.generated'
import { useEffect } from 'react'
import { track } from '@/features/shared/analytics/trackingQueue'
import { AnalyticsEvent } from '@shipt/analytics-member-web'
import { urlPathnameToLocationName } from '@/features/shared/analytics/utils'

export const OrdersQuery = 'Orders'
export const ActiveOrdersQuery = 'Active orders'
export const LastDeliveredOrderQuery = 'Last delivered order'
export const UpdateOrderMutation = 'Update order'

export type ActiveOrdersQueryKey = readonly [typeof ActiveOrdersQuery]
const activeOrdersQueryOptions = () =>
  queryOptions({
    queryKey: [ActiveOrdersQuery] as const,
    queryFn: fetchActiveOrders,
  })

export const useQueryActiveOrders = () => {
  const hasPlacedFirstOrder = useUserHasPlacedFirstOrder()

  return useQuery({
    ...activeOrdersQueryOptions(),
    placeholderData: (prevData) => prevData,
    enabled: Boolean(hasPlacedFirstOrder),
  })
}

const ordersHistoryQueryOptions = queryOptions({
  queryKey: [OrdersQuery] as const,
  queryFn: fetchOrdersHistory,
})

const ordersHistorySelect = (response: NormalizedOrderHistoryResponse) => {
  const upcomingOrders = orderBy(
    response.upcomingOrders ?? [],
    ['id'],
    ['desc']
  )
  const pastOrders = orderBy(response.pastOrders ?? [], ['id'], ['desc'])
  return { upcomingOrders, pastOrders }
}
export const useQueryOrdersHistory = () => {
  const user_id = useUserId()
  return useQuery({
    ...ordersHistoryQueryOptions,
    enabled: Boolean(user_id),
    select: ordersHistorySelect,
  })
}

export const lastDeliveredOrderQueryOptions = queryOptions({
  queryKey: [LastDeliveredOrderQuery] as const,
  queryFn: fetchLastDeliveredOrder,
  staleTime: minutesToMilliseconds(15),
})

export const useQueryLastDeliveredOrder = () => {
  const storeParams = useStoreParams()
  const user_id = useUserId()
  const { store_location_id } = storeParams
  const hasPlacedFirstOrder = useUserHasPlacedFirstOrder()
  const shouldEnable = Boolean(
    hasPlacedFirstOrder && store_location_id && user_id
  )

  return useQuery({
    ...lastDeliveredOrderQueryOptions,
    enabled: shouldEnable,
    // this is to ensure useQuery does not do any deep object comparison
    structuralSharing: false,
  })
}

const OrderQuery = 'order'

export const orderQueryOptions = (id: number) =>
  queryOptions({
    queryKey: [OrderQuery, { order_id: id }] as const,
    queryFn: fetchOrder,
    staleTime: minutesToMilliseconds(15),
  })
export type OrderQueryKey = readonly [typeof OrderQuery, { order_id: number }]

export const useQueryOrder = (
  id: number,
  options: {
    enabled?: boolean
    initialData?: Order
    refetchInterval?: number
  } = { enabled: true }
) => {
  const user_id = useUserId()

  return useQuery({
    ...orderQueryOptions(id),
    ...options,
    enabled: Boolean(id && options.enabled && user_id),
  })
}

const orderTrackingQueryOptions = (
  orderId: number,
  orderStatus: Order['status']
) =>
  queryOptions({
    queryKey: ['order tracking', orderId] as const,
    queryFn: fetchOrderTracking,
    // we would like members or have realtime experience until we get the websockets
    // average rate for shoppers to add products is around 85seconds, hence 90secs refetchinterval when order status is shopping
    ...(orderStatus === 'shopping' && {
      refetchInterval: secondsToMilliseconds(90),
    }),
  })
export type OrderTrackingQueryKey = readonly [string, number]

export const useQueryOrderTracking = (
  ...args: Parameters<typeof orderTrackingQueryOptions>
) => useQuery(orderTrackingQueryOptions(...args))

export const useQueryOrderEngagementETA = (
  orderId: number,
  isEnabled: boolean
) => {
  const data = useOrderEngagementQuery(
    { orderId },
    {
      refetchInterval: minutesToMilliseconds(4),
      enabled: Boolean(orderId) && isEnabled,
    }
  )
  useEffect(() => {
    if (data.status !== 'pending') {
      track({
        eventName: AnalyticsEvent.BFFQueryTracked,
        properties: {
          is_bff: false,
          feature: 'GetOrderEngagement',
          is_success: data.status === 'success',
          location: urlPathnameToLocationName(),
        },
      })
    }
  }, [data.status])
  return data
}
