import { type DeliveryWindow } from '@/features/shop/services/DeliveryWindows/types'
import {
  type OrderStatus,
  ORDER_STATUS,
} from '@/features/shared/constants/global'
import { ORDER_STATUS_TEXTS } from '@/features/shop/components/OrderDetails/OrderDetailsStatus/statuses'

export const pastStatuses: OrderStatus[] = [
  ORDER_STATUS.DELIVERED,
  ORDER_STATUS.CANCELLED,
  ORDER_STATUS.ABANDONED,
  ORDER_STATUS.RETURNED,
]
export const isPastOrderStatus = (status: OrderStatus) => {
  return pastStatuses.includes(status)
}

const upcomingStatuses: OrderStatus[] = [
  ORDER_STATUS.OPEN,
  ORDER_STATUS.SHOPPING,
  ORDER_STATUS.CLAIMED,
  ORDER_STATUS.PROCESSED,
  ORDER_STATUS.READY_FOR_PICKUP,
]
export const isUpcomingOrderStatus = (status: OrderStatus) => {
  return upcomingStatuses.includes(status)
}

export const isOutForDeliveryOrderStatus = (status: OrderStatus) => {
  return status === ORDER_STATUS.PROCESSED
}

export const isDeliveredOrderStatus = (status: OrderStatus) => {
  return status === ORDER_STATUS.DELIVERED
}

export const isCancelledOrderStatus = (status: OrderStatus) => {
  const cancelledStatuses: OrderStatus[] = [
    ORDER_STATUS.CANCELLED,
    ORDER_STATUS.ABANDONED,
    ORDER_STATUS.RETURNED,
  ]
  return cancelledStatuses.includes(status)
}

export const isReadyForPickupOrderStatus = (status: OrderStatus) => {
  return status === ORDER_STATUS.READY_FOR_PICKUP
}

export const isShoppingActiveStatus = (status: OrderStatus) => {
  return status === ORDER_STATUS.SHOPPING || status === ORDER_STATUS.PROCESSED
}

export const getOrderStatusText = (
  status: OrderStatus,
  isShopOnly: boolean
): string => {
  switch (status) {
    case ORDER_STATUS.DELIVERED:
      if (isShopOnly) {
        return ORDER_STATUS_TEXTS.PICKED_UP
      } else {
        return ORDER_STATUS_TEXTS.DELIVERED
      }
    case ORDER_STATUS.OPEN:
      return ORDER_STATUS_TEXTS.ORDER_PLACED
    case ORDER_STATUS.CLAIMED:
      return ORDER_STATUS_TEXTS.ORDER_CLAIMED
    case ORDER_STATUS.SHOPPING:
      return ORDER_STATUS_TEXTS.SHOP_IN_PROGRESS
    case ORDER_STATUS.PROCESSED:
      return ORDER_STATUS_TEXTS.SHOP_COMPLETE
    case ORDER_STATUS.RETURNED:
    case ORDER_STATUS.CANCELLED:
    case ORDER_STATUS.ABANDONED:
      return ORDER_STATUS_TEXTS.ORDER_CANCELLED
    case ORDER_STATUS.READY_FOR_PICKUP:
      return ORDER_STATUS_TEXTS.READY_FOR_PICKUP
    default:
      return ''
  }
}

export const getTimeSlotByStartTime = (
  startTime: string,
  timeSlots: DeliveryWindow[]
): DeliveryWindow | undefined => {
  return timeSlots.find((slot) => slot.start_time === startTime)
}
